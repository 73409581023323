<template>
  <a-row :gutter="25">
    <a-col :xxl="24" :lg="24" :md="24" :xs="24">
      <Suspense>
        <template #default>
          <div headless>
            <sdCards>
              <TableWrapper class="table-responsive title-normal">
                <a-table :dataSource="dataForm.data" :columns="dataForm.columns" :pagination="false" :bordered="bordered" :locale="localeData" />
              </TableWrapper>
              <br />
              <a-row :gutter="25">
                <a-col :lg="24">
                  <!--show-size-changer-->
                  <a-pagination
                    v-model:page-size="dataForm.pageSize"
                    :total="dataForm.totalRecords"
                    :current="dataForm.current"
                    @showSizeChange="onShowSizeChange"
                    @change="onChange"
                    style="float: right"
                  />
                </a-col>
              </a-row>
            </sdCards>
          </div>
        </template>
        <template #fallback>
          <sdCards headless>
            <a-skeleton active />
          </sdCards>
        </template>
      </Suspense>
    </a-col>
  </a-row>
</template>
<script>
import { TableWrapper, UserTableStyleWrapper } from './styled';
import { reactive } from 'vue';
import { Empty } from 'ant-design-vue';

const DataTableComponent = {
  name: 'DataTableComponent',
  components: { UserTableStyleWrapper, TableWrapper, Empty },
  props: {
    columns: null,
    data: null,
    totalRecords: null,
    bordered: {type: Boolean, default: false}
  },
  watch: {
    data: function(newValue) {
      this.dataForm.data = newValue;
    },
    totalRecords: function(newValue) {
      this.dataForm.totalRecords = newValue;
      this.dataForm.current = 1;
    },
  },
  emits: ['getCurrentPaginator'],
  async setup(props, { emit }) {

    var dataForm = reactive({
      showTableByRequest: true,
      columns: props.columns,
      data: props.data,
      totalRecords: props.totalRecords,
      current: 1,
      pageSize: 10,
    });

    const localeData = {
      emptyText: (<a-empty v-slot:image="Empty.PRESENTED_IMAGE_DEFAULT" description="Ningún resultado encontrado"/>)
    }

    const onShowSizeChange = (current, pageSize) => {
      console.log(current, pageSize);
    };

    const onChange = current => {
      dataForm.current = current;
      emit('getCurrentPaginator', current);
    };

    return {
      dataForm,
      onShowSizeChange,
      onChange,
      localeData,
    };
  },
};

export default DataTableComponent;
</script>
<style scoped>
.bt-option-card {
  width: 100%;
  color: #212529;
  background: none;
  border: none;
  padding-left: 15px !important;
  padding: 5px;
  font-size: 12px;
  margin-bottom: 0.5rem;
}
.bt-option-card:hover {
  cursor: pointer;
  background: #f1efef;
}
.list-data {
  margin-bottom: 0.5rem;
}
.box-event {
  border: 1px solid #bcbdbe;
  border-radius: 7px;
  padding: 0 !important;
  margin-left: 0.5rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
}
.btn-add-event {
  background: #e70e4c;
  color: #fff;
  border-radius: 20px;
  border: none;
  padding: 7px;
}
.btn-add-event:hover {
  cursor: pointer;
}
.title-more-events {
  color: #e70e4c;
  font-size: 14px;
  font-weight: bold;
}
.title-more-events:hover {
  cursor: pointer;
}
.type-btn:hover {
  cursor: pointer;
}
.input-field {
  border-radius: 30px;
}
</style>
