import Styled from 'vue3-styled-components';

const TableWrapper = Styled.div`
box-shadow: 0px 3px 6px #00000029;
table{
    border-radius: 20px 20px 0px 0px;
    thead{
        tr{
            border-radius: 10px;
            th{
                background: #404040;
                color: white;
            }
        }
    }
    tbody{
        tr:nth-child(even) {
            background-color: #F1F3F4;
        }
    }
`;

export {
  TableWrapper,
};
